.input-text-style {
  position: relative;

  ion-input {
    --background: #f2f2f2 !important;
    --padding-bottom: 15px !important;
    --padding-end: 10px !important;
    --padding-start: 10px !important;
    --padding-top: 15px !important;
    margin-top: 10px;
    border-radius: 5px;
    // color: black;
  }

  
}