.content {
    // &.gradient {
    //     background-image: linear-gradient(to bottom, rgba(255, 230, 239, 0), #ffdee8);
    // }
    // --box-shadow: 0;
    line-height: 30px;
    border-radius: 6px;

    .number_title {
        font-size: 50px;
        font-weight: bold;
    }
    .title {
        font-size: 12px;
        font-weight: 500;

    }
    .sub_title {
        font-size: 8px;
    }

}