// .support_desktop {

.concrete-row-style {

    @media (min-width: 768px) {
        display: inline-block;
        width: 49%;
    }

    @media (min-width: 1268px) {
        width: 25%;
    }

    @media (min-width: 1568px) {
        width: 20%;
    }

    ion-card {

        @media (min-width: 768px) {
            width: 97%;
        }

        margin: 20px 0 0 0;
        padding: 20px 20px 20px 20px;

        .fully-cured-color {
            color: #02bc77;
        }
        
        ion-card-content {
            margin-top: 20px;
            padding: 0;


            

            
        }

        .border-left {
            border-left: 1px solid #f0f0f0;
            padding-left: 10px;

            .to-fully {
                font-size: 9px !important;
            }
            
        }

    }

    .icon {
        width: 40px;
    }
}

// }