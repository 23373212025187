.pieChart {
    position: relative;
    .deg90 {
        transform: rotate(90deg);
        background-color: #f5f5f5;
        position: absolute;
        /* left: inherit; */
        right: -72px;
        z-index: 3;
        width: auto;
        top: 50%;
        /* transform: translatey(-50%); */
        padding: 10px;
        width: 164px;
        text-align: center;
        border-radius: 5px;
    
    }
    .pie_title {
        font-size: 12px;
        color: #5d666e;
    }
}