.sensors-row-style {

    ion-col {
        font-size: 12px;
        font-weight: 500;
        color: #5d666e;
        position: relative;
        text-align: center;

        @media (min-width: 768px) {
            font-size: 14px;
        }

        @media (min-width: 1024px) {
            font-size: 16px;
        }

        // &::after {
        //     position: absolute;
        //     width: 90%;
        //     height: 90%;
        //     left: 50%;
        //     top: 50%;
        //     transform: translate(-50%, -50%);
        //     // content: "";

        // }

        &.info ion-icon {
            background-color: #e8f3fa;
            color: rgb(65, 121, 228);
        }

        &.trash ion-icon {
            background-color: #f5e9ed;
            color: rgb(220, 77, 91);

        }

        &.edit ion-icon {
            background-color: #e1f5ee;
            color: rgb(84, 185, 125);

        }

        &.setting ion-icon {
            background-color: #ededed;
            color: rgb(145, 149, 153);

        }

        &.icon {
            font-size: 17px;

            ion-icon {
                // background: #e9e9e9;
                /* padding: 0; */
                padding: 4px;
                border-radius: 5px;
                // position: absolute;
                // width: 90%;
                // height: 90%;
                // left: 50%;
                // top: 50%;
                // transform: translate(-50%, -50%);
            }

        }
    }


}


// }
.info-modal {
    --height: 80px;
    --width: 150px;
    border-radius: 5px;
    overflow: hidden;

    .container-info {
        /* justify-content: center; */
        /* align-items: center; */
        /* display: flex; */
        height: 100%;
        padding: 15px 0 0 0;
        .text-center {
            width: 100%;
            display: inline-block;
            margin-top: 5px;
        }
    }
}