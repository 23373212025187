.chart-pie-component {
    .width0 {
        position: absolute;
        width: 0;
        height: 0;
    }

    tspan {
        fill: #ed135b;

        >tspan {
            font-size: 38px;
        }
    }

    // #header-shape-gradient {
    //     --color-stop: #ed135b;
    //     --color-bot: #ff5d93;
    // }

    // transform="matrix(1,0,0,1,0,0)"

    

    // path:not([transform="matrix(1,0,0,1,0,0)"]) {
    // path[transform="matrix(1,0,0,1,11.8141,16.1378)"],
    // path[transform="matrix(1,0,0,1,-19.0653,-6.0428)"],
    // path[transform="matrix(1,0,0,1,16.1378,-11.8141)"] 
    // {

    //     // transform: translate(-3px, -3px);
    //     // fill: url("#rr-59-270-rgba_255_150_185_1_-rgba_237_19_91_1_");
    //     fill: url(#chart-pie-gradient) #ff5d93;

    // }

    // path {
    //     fill: #ebebeb;
    //     // transform: translate(-3px, -3px);
    //     // fill: url("#rr-59-270-rgba_255_150_185_1_-rgba_237_19_91_1_");
    // }

    // <path d="M267.5,150.00000000000003A100,100,0,0,1,137.2859584430427,245.32634312085224L147.86087298797776,211.96212302855398A65,65,0,0,0,232.5,150.00000000000003Z" fill="url('#rr-0-xr___100_167.5_150__rgba_235_235_235_1__65-rgba_235_235_235_1__82')" stroke="#fafafa" stroke-linecap="round" fill-opacity="1" stroke-width="0.000001" stroke-opacity="0" stroke-dasharray="none"></path>
    // <path d="M267.5,150.00000000000003A100,100,0,0,1,137.2859584430427,245.32634312085224L147.86087298797776,211.96212302855398A65,65,0,0,0,232.5,150.00000000000003Z" fill="url('#rr-0-xr___100_167.5_150__rgba_241_241_241_0.9__65-rgba_241_241_241_0.9__82')" stroke="#fafafa" stroke-linecap="round" fill-opacity="1" stroke-width="undefined" stroke-opacity="0.9" stroke-dasharray="none" transform="matrix(1,0,0,1,11.8141,16.1378)"></path>
    // path:nth-child(2) {
    //     transform: translate(-8px, 0px);


    // }
    // path:nth-child(3) {
    //     transform: translate(1px, 3px);
    // }
}