.home-footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    background-color: #f8f8f8;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    transform: translateX(-50%);

    ion-col {
        // text-align: center;
        margin: 10px auto;

        ion-img.resize {
            height: 30px;
        }

        .circle-add {

            width: 70px;
            height: 70px;
            // background-color: red;
            margin: 0px auto;
            border-radius: 50%;
            background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(93, 102, 110, 1) 100%);
            box-shadow: -2px -1px 15px -2px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: -2px -1px 15px -2px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: -2px -1px 15px -2px rgba(0, 0, 0, 0.75);
            justify-content: center;
            align-items: center;
            display: flex;
            ion-icon {
                // background-color: red;
                font-weight: 20px;
                color: white;
            }
        }
    }

}