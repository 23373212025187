.panel-add {
    background-color: #fafafa;
    &.fixed {
        position: fixed;
        bottom: 0px;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        z-index: 2;
    }
    .content {
        // height: 50px;
        z-index: 9;
        padding: 5px 0 0 0;
        max-width: 700px;
        margin: 0px auto;

        ion-col {
            padding: 10px;

            .button-black {
                border: solid 1px rgba(0, 0, 0, 0);
                background: rgb(0, 0, 0);
                background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(93, 102, 110, 1) 100%);
                color: white;
                border-radius: 8px;
                padding: 10px 0;
                text-align: center;
                font-weight: bold;
            }

            .button-reset {
                padding: 10px 0;
                text-align: center;
                border-radius: 8px;
                border: 1px solid #5d666e;
                font-weight: bold;
                color: #5d666e;
            }
        }
    }
}


.support_desktop {
    max-width: 700px;
    margin: 0px auto;
}

// .structure_status {
    .high {
        color: #ed135b;
    }
    .low {
        color: #d8b31c;
    }
    .fully-cured {
        color: #02bc77;
    }
// }
