.popover-theme {
    // background-color: orange;
    position: relative;
    display: inline-block;
    padding-right: 20px;


    li {
        margin: 0;
        padding: 5px;

    }
    p {
        color: black;
    }
    .popover-icon {
        margin-top: 3px;
        position: absolute;
        top: 0px;
        right: 3px;
    }
}