.statisticalPage {

    .fullyCured-day {
        h2 {
            font-size: 55px;
            text-align: right;
            margin-top: 0;
            padding-right: 20px;
            font-weight: bold;
        }

    }

    p {
        color: #b3b3b3;
    }

    .slider_fullyCured {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        margin-right: 50px;

        span {
            height: 5px;
            display: inline-block;
            background-color: #eaeaea;
            width: 5px;
            justify-content: space-between;

            &.green {
                background-color: green;
            }
        }
    }

    .chart {
        position: relative;

        .placeholder {
            color: #000000;
            text-align: center;
            font-weight: bold;
            font-size: 12px;
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .row_sensor {
        line-height: 30px;
    }
    .header {
        align-items: center;
        ion-col {
            color: #5d666e;
            font-size: 13px;
            ion-icon {
                margin-top: 4px;
            }
        }
    }
    .refresh {
        color: #4791ff;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
        background-color: transparent;
    }
}