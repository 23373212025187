.sensors-grid-style {

    .button-border {
        font-size: 14px;
        padding: 4px 5px;
        width: 98%;
        display: inline-block;
        text-align: center;

        &.general-config {
            color: #5d666e;
        }
    }

    .header {
        ion-col {
            font-size: 10px;
            font-weight: bold;
            color: #5d666e;
            text-align: center;
        }
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
}