ion-menu {
    opacity: 0.95;
    --background: transparent;
    @media (min-width: 768px) {
        --min-width: 400px;
        overflow: hidden;
    }
    // @media (max-width: 500px) {
    //     --max-width: 90%;
    //     overflow: hidden;
    // }
    
    
    ion-content {
        --background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(93, 102, 110, 1) 100%);
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;

        // ion-list {
        //     --background: transparent;
        ion-item {
            --background: transparent;
            color: #f8f9fa;
            font-size: 20px;
            @media (max-width: 600px) {
                font-size: 15px;
                padding-left: 0px;
            }
        }

        ion-icon {
            // color: white;
            color: #f8f9fa;
            // background-color: red;
        }

        // }
    }
}