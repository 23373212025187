.img-title-row-style {
    line-height: 30px;

    ion-row {
        border-bottom: 1px solid #ededed;
        margin-bottom: 5px;

        ion-col {
            align-items: center;
        }

    }
}