// @import "./varibles.scss";
// @import "./variables.css";
// $background-color: red;
$background-color: rgb(250 250 250);

* {
    padding: 0;
    margin: 0;
}

@import "./override.scss";
@import "./styles/share.scss";


body {
    // background-color: $backgroundColor;
    --ion-background-color: rgb(250 250 250);
}

ion-content {
    --padding-bottom: 40px;
    --padding-end: 20px;
    --padding-start: 20px;
    --padding-top: 40px;

    @media (max-width: 400px) {

        // --padding-top: 10px;
        &.mobile-p-0 {
            --padding-end: 0;
            --padding-start: 0;
        }
    }

    &.m-bottom {
        --padding-bottom: 100px !important;
    }
}

// font
input,
textarea,
.back-input span {
    font-size: 14px !important;
}

.center_vertical {
    display: flex;
    align-items: center;
    justify-content: center;
}

// margin - padding
.m-0 {
    margin: 0px !important;
}

.m-1 {
    margin: 1px !important;
}

.m-5 {
    margin: 5px !important;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.p-0 {
    padding: 0px !important;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-5 {
    padding-bottom: 5px;
}

.pt-5 {
    padding-top: 5px;
}

// text
.blod {
    font-weight: bold;
}

.s-size {
    font-size: 10px !important;
}

.nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

p.label,
.label {
    color: #90959a;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

// color
.c-black {
    color: black;
}

.c-gray {
    color: #b3b3b3;
}

.c-base-gray {
    color: #5d666e;
}

// mouse
.cursor {
    cursor: pointer;
}

// button
.button-border {
    cursor: pointer;
    color: #4791ff;
    border: 1px solid #f0f0f0;
    padding: 5px 20px;
    border-radius: 5px;

    &.save {
        color: green;
        margin-top: 10px;
        display: inline-block;
        margin-right: 5px;
    }
}


// input
.back-input {
    background: #f2f2f2 !important;
    padding: 15px 10px;
    margin-top: 10px;
    border-radius: 5px;
    align-items: center;
}

.border_edit {
    border: 1px solid #e1e1e1;
    padding: 10px;
}

// line
.line {
    height: 1;
    background-color: #e1e1e1;
    margin: 10px 0;
}


// grid
.flex {
    justify-content: center;
    display: flex;
    align-items: center;

    &.j-start {
        justify-content: start;
    }

    &.j-between {
        justify-content: space-between;
    }

    &.full>div {
        width: 100%;
    }

    &.suppot-400 {
        &.full>div {
            @media (max-width: 400px) {
                width: 48%;
            }
        }

        @media (max-width: 400px) {
            flex-wrap: wrap;
        }
    }
}

// cursor
.not-allowed {
    cursor: not-allowed;
}

.concrete-card-item {

    padding-left: 10px;
    padding-right: 10px;

    .image-col {
        background-color: #f0f0f0;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        text-align: center;
        display: flex;

        ion-img {
            height: 30px;
            width: 10px;
        }
    }

}