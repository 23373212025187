.segment {
    background-color: #f5f5f5;
    margin: 5px auto;
    padding: 0 5px;
    max-width: 250px;
    border-radius: 8px;

    ul {
        width: 100%;
        // margin: 0px auto;
        // height: 50px;
        list-style: none;
        display: flex;
        // justify-content: space-between;
        padding: 3px 0;

        li {
            padding: 3px 5px;
            border-radius: 8px;
            width: 50%;
            color: #999999;
            &.active {
                border: solid 1px rgba(0, 0, 0, 0);
                background: rgb(0, 0, 0);
                // https://cssgradient.io/
                background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(93, 102, 110, 1) 100%);
                color: #f8f9fa;
                border-radius: 15px;

            }
        }
    }
}