.menu-inner {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

svg > g[class^="raphael-group-"] > text{
    display: none;
}
.fusioncharts-container svg {
    touch-action: inherit!important;
}

ion-popover {
    --backdrop-opacity:var(--ion-backdrop-opacity, 0.4)!important;
}
.popover-content {
    padding: 8px;
    li {
        margin-bottom: 5px;
        text-align: center;
    }
}

.modal-wrapper {
    border-radius: 5px!important;
}