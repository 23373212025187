ion-modal {
    ion-content {
        --padding-end: 20px;
        --padding-start: 20px;
        // --background: transparent;
        background: white;

        // .ion-page {
            overflow: hidden;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        // }
    }

}

.modal_bottom {
    .modal-shadow {
        top: 40%;
    }
    .modal-wrapper {
        height: 60%;
        top: 40%;
        width: 95%;
        margin: 0px auto;
        position: absolute;
        background-color: transparent;
        overflow: inherit;
    
        @media (min-width: 768px) {
            display: inline-block;
            max-width: 700px;
        }
    
        &::after {
            content: "";
            background-color: white;
            width: 130px;
            height: 5px;
            top: -15px;
            left: 50%;
            position: absolute;
            border-radius: 8px;
            transform: translateX(-50%);
        }
    }
}